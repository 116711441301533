import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../../shared/store/authProvider";
import ApiService from "../../../../shared/services/apiService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import SectionComponent from "../../../../shared/components/sectionComponent";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { ResultStatus, API_ENDPOINT, CrudAction } from "../../../../shared/types/enums";
import LayoutService from "../../../../shared/services/layoutService";
import { Typography, Divider, Grid, Tooltip, IconButton, MenuItem, Select } from "@material-ui/core";
import MatSpecService from "../../../../shared/services/theme/matSpecService";
import ComplianceDefaultEditService from "./complianceDefaultEditService";
import AgGridDropdownWithStatusCellRenderer from "../../../../shared/components/ag-grid/agGridDropdownWithStatusCellRenderer";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import ToastService from "../../../../shared/services/toastService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";

class ComplianceDefaultEditComponent extends Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        this.handleHide = this.handleHide.bind(this);
        this.state = {
            isReadOnly: props.inputAction === CrudAction.UPDATE,
            isEditing: true,
            showDialog: false,
            modalAgNode: null,
            selectedEntityId: 0,
            selectedFieldId: props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.fieldId : 0,

            agGridUtils: new AgGridUtil("gstLovID", {
                dropDownRenderer: AgGridDropdownWithStatusCellRenderer,
            }),
        };
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {
        this.fetchData();
    }

    /** API Fetch */
    fetchData = (_ignoreCache = false) => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

        if (this.state.selectedFieldId !== 0) {
            this.apiSubscriptions.add(
                combineLatest([
                    ComplianceDefaultEditService.getDefaultMappingsByEntityFieldIdListAsOBS(this.context.user.tenantId, this.state.selectedFieldId),
                    ComplianceDefaultEditService.getPorzioGSTFieldListAsOBS(this.context.user.tenantId, this.state.selectedFieldId),
                    ComplianceDefaultEditService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 1),
                    ComplianceDefaultEditService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 2),
                ]).subscribe(
                    // success
                    ([_data, _porzioGSTFieldList, _profileFieldList, _transactionFieldList]) => {

                        // sort data
                        _data = (_data || []).sort((x, y) => x.lovKey.localeCompare(y.lovKey));

                        // remove spciality and sort
                        _profileFieldList = _profileFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                        // remove product and sort
                        _transactionFieldList = _transactionFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                        // combine product and transactiony, then sort
                        const _profileAndTransactionFieldList = ([..._profileFieldList, ..._transactionFieldList]).sort((a, b) => a.fieldName.localeCompare(b.fieldName));


                        //---> Format _porzioGSTFieldList
                        let emptyElement = null;
                        const emptyElementIndex = _porzioGSTFieldList.findIndex(x => x.lovId === 757); // find the emptyElement's index
                        if (emptyElementIndex >= 0) { // if exists remove it
                            const spliced = _porzioGSTFieldList.splice(emptyElementIndex, 1) || [];
                            if (DataService.hasElements(spliced)) {
                                emptyElement = spliced[0];
                                emptyElement.lovKey = "-empty-";
                            }
                        }
                        _porzioGSTFieldList = _porzioGSTFieldList.sort((x, y) => { return x.lovKey.localeCompare(y.lovKey); }); // sort the reset of the array by key
                        if (emptyElement) { _porzioGSTFieldList = [emptyElement, ..._porzioGSTFieldList]; } // add the empty element at the top if exists
                        //<---

                        this.setState(
                            {
                                data: _data,
                                porzioGSTFieldList: _porzioGSTFieldList,
                                profileFieldList: _profileFieldList,
                                transactionFieldList: _transactionFieldList,
                                profileAndTransactionFieldList: _profileAndTransactionFieldList,
                                fieldList: [{ fieldId: 0, fieldName: "Select" }, ..._profileAndTransactionFieldList],
                            },
                            // change the state after all the above are assigned
                            () => { this.setState({ fetchResult: ResultStatus.LOADED }); }
                        );
                    },
                    // onError
                    (error) => {
                        console.log("Error:", error);
                        this.setState({ fetchResult: ResultStatus.ERROR });
                    }
                )
            );
        } else {
            this.apiSubscriptions.add(
                combineLatest([
                    ComplianceDefaultEditService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 1),
                    ComplianceDefaultEditService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 2),
                ]).subscribe(
                    // success
                    ([_profileFieldList, _transactionFieldList]) => {

                        // remove spciality and sort
                        _profileFieldList = _profileFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                        // remove product and sort
                        _transactionFieldList = _transactionFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                        // combine product and transactiony, then sort
                        const _profileAndTransactionFieldList = ([..._profileFieldList, ..._transactionFieldList]).sort((a, b) => a.fieldName.localeCompare(b.fieldName));

                        this.setState(
                            {
                                data: [],
                                porzioGSTFieldList: [],
                                profileFieldList: _profileFieldList,
                                transactionFieldList: _transactionFieldList,
                                profileAndTransactionFieldList: _profileAndTransactionFieldList,
                                fieldList: [{ fieldId: 0, fieldName: "Select" }, ..._profileAndTransactionFieldList],
                            },
                            // change the state after all the above are assigned
                            () => { this.setState({ fetchResult: ResultStatus.LOADED }); }
                        );
                    },
                    // onError
                    (error) => {
                        console.log("Error:", error);
                        this.setState({ fetchResult: ResultStatus.ERROR });
                    }
                )
            );
        }
    };

    onGridReady = (event) => {
        event.api.closeToolPanel();
        event.api.sizeColumnsToFit();
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
        this.gridApi.setDomLayout("autoHeight");
    };

    onEntitySelectClick = (event) => {
        console.log(event);
        this.setState({ selectedEntityId: event.target.value });
        switch (event.target.value) {
            case 0:
                this.setState({ fieldList: [{ fieldId: 0, fieldName: "Select" }, ...this.state.profileAndTransactionFieldList] });
                this.setState({ selectedFieldId: 0 });
                break;
            case 1:
                this.setState({ fieldList: [{ fieldId: 0, fieldName: "Select" }, ...this.state.profileFieldList] });
                break;
            case 2:
                this.setState({ fieldList: [{ fieldId: 0, fieldName: "Select" }, ...this.state.transactionFieldList] });
                break;
            default:
                this.setState({ selectedEntityId: event.target.value });
        }
        this.gridApi.setFilterModel(null);
    }

    onFieldSelectClick = (event) => {
        this.setState({ selectedFieldId: event.target.value },
            this.fetchData, // https://stackoverflow.com/questions/37401635/react-js-wait-for-setstate-to-finish-before-triggering-a-function
        );
    }

    methodFromParent = (cell, data) => {
        console.log("methodFromParent");
        this.setState({ showDialog: true });
        this.setState({ modalAgNode: data });
    };

    handleHide() {
        this.setState({ showDialog: false });
    }

    handleSubmit = async (_formikProps) => {
        var _fieldId = 0;
        var arrNodes = [];
        this.gridApi.forEachNode((rowNode) => {
            _fieldId = rowNode.data.fieldId;
            const gstLovId = Number(rowNode.data.gstLovID);
            const gstLov = DataService.getFirstOrDefault(this.state.porzioGSTFieldList.filter(x => x.lovId === gstLovId), null);
            arrNodes.push({
                fieldId: rowNode.data.fieldId,
                lovID: rowNode.data.lovID,
                lovKey: rowNode.data.lovKey,
                gstLovID: rowNode.data.gstLovID,
                gstLovKey: gstLovId === 757 ? "" : (gstLov ? gstLov.lovKey : "")
            });
        });

        // 2) extract the formik values into an object
        var mappedObj = {
            fieldId: _fieldId,
            lovID: 0,
            lovKey: "",
            gstLovID: 0,
            gstLovKey: "",
            fieldValue: JSON.stringify(arrNodes),
            fieldName: "",
        };

        // 4) save to Api and subscribe for the result
        ApiService.setOBS("POST", API_ENDPOINT.TENANT,
            `/ComplianceMapping/SaveDefaultMapping/${this.context.user.tenantId}/${this.context.user.userId}`,
            JSON.stringify(mappedObj)
        ).subscribe(
            (successResult) => {
                if (successResult) {
                    ToastService.showSuccess("Sucessfully saved.");
                    this.setState({ fetchResult: ResultStatus.SUCCESS });
                    this.props.refreshList(true);
                    this.props.onClose(false);
                } else {
                    ToastService.showError("Error while saving.");
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            },
            (errorResult) => {
                ToastService.showError("Error while saving.");
                console.error("Error while saving", errorResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        );
    }

    porzioGstFieldValueComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (DataService.isNullOrUndefined(nodeA.data.gstLovKey))
            return 1;
        else if (DataService.isNullOrUndefined(nodeB.data.gstLovKey))
            return -1;
        else if (nodeA.data.gstLovKey === nodeB.data.gstLovKey) return 0;
        return (nodeA.data.gstLovKey > nodeB.data.gstLovKey) ? 1 : -1;
    };

    render() {
        const { classes } = this.props;
        const isNew = this.props.inputAction === CrudAction.CREATE;
        const isUpdate = this.props.inputAction === CrudAction.UPDATE;

        if (isUpdate && RolePermissionService.DEFAULT_MAPPING_DETAIL.cannotView) {
            return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack(); });
        } else {
            this.state.agGridUtils.disableEditability(this.state.isReadOnly);
            switch (this.state.fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return (<PageLoadingComponent classes={classes} label={`Loading Default Mapping...`} />);
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <div id="MainUsersGrid">
                            <div className="IconBg marginTop10px tableTitleHead">
                                <CompliancePageHeaderComponent classes={classes} label={" "} noDivider
                                    fieldComplianceSelect selectedFieldId={this.state.selectedFieldId}
                                    fieldSelectData={this.state.fieldList}
                                    onFieldSelectClick={(event) => { this.onFieldSelectClick(event); }} />
                            </div>
                            <div>
                                <SectionComponent classes={classes} 
                                    enableEditJsx={isNew ? LayoutService.getReadOnlyActionsSolo(this, isNew, () => { this.handleSubmit() }) :
                                        RolePermissionService.DEFAULT_MAPPING_DETAIL.cannotEdit ? null :
                                            LayoutService.getReadOnlyActionsSolo(this, isNew, () => { this.handleSubmit() })
                                    }
                                />
                            </div>
                            <div {...LayoutService.getAgGridStyles()} style={{overflowX:"hidden"}}>
                                <AgGridReact
                                    columnDefs={ComplianceDefaultEditService.getColumnDefs(this)}
                                    rowData={this.state.data}
                                    pagination={true}
                                    paginationPageSize={50}
                                    frameworkComponents={this.state.agGridUtils.frameworkComponents}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        rowHeight: 32
                                       
                                    }}
                                    onGridReady={(event) => {
                                        this.onGridReady(event);
                                        this.state.agGridUtils.setGridParams(event, true);
                                        this.state.agGridUtils.disableEditability(this.state.isReadOnly);
                                    }}
                                ></AgGridReact>
                            </div>
                        </div>
                    );

                case ResultStatus.ERROR:
                    return (<PageErrorComponent label={`Error Loading Default Mapping`} classes={classes} onRetry={() => { this.fetchData(true); }} />);
                default:
                    return null;
            }
        }
    }
}

class CompliancePageHeaderComponent extends Component {
    render() {
        const { small, classes, history, label, back, menu, search, entityComplianceSelect, fieldComplianceSelect, countrySelect, importTemplate, uploadFile, save, add, addCircle, view, reload, ok, cancel, noDivider, noDividerSpacing } = this.props;
        const showButtons = back || menu || search || entityComplianceSelect || countrySelect || importTemplate || uploadFile || save || add || addCircle || view || reload || ok || cancel;
        return (
            <React.Fragment>
                <Grid className={classes.flexNoShrink} container direction="column">
                    <Grid container direction="row" alignItems="center">

                        <Typography variant={small ? "h6" : "h5"} className={classes.headerTitle}>
                            {label}
                        </Typography>

                        {fieldComplianceSelect ? (
                            <>
                                <Typography>Field:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="fieldComplianceSelectLabel"
                                    id="entityComplianceSelect"
                                    value={this.props.selectedFieldId}
                                    onChange={this.props.onFieldSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.fieldSelectData.map((el) => (
                                        <MenuItem key={el.fieldId} value={el.fieldId}>
                                            {el.fieldName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                    </Grid>
                    {noDivider || noDividerSpacing ? (
                        <span>{!noDivider && MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    ) : (
                        <span className={clsx(showButtons ? classes.pageHeaderDividerWithIcons : classes.pageHeaderDividerWithoutIcons)}>{MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    )}
                </Grid>
            </React.Fragment>
        );
    }
}

export default LayoutService.getHocComponenet(ComplianceDefaultEditComponent);